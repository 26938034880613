import React from "react";

import styles from "../css/ProductsStateBox.module.css";

const ProductsStateBox = ({ bgColor, state, productsCount, percentage }) => {
  return (
    <>
      <div className={styles.container} style={{ "--bg-color": bgColor }}>
        <p className={styles.state}>{state}</p>
        <p className={styles.productsCount}>{productsCount}</p>
        <p className={styles.percentage}>{percentage}</p>
      </div>
    </>
  );
};

export default ProductsStateBox;
