import React, { useEffect, useContext, useLayoutEffect } from "react";
import { RouterProvider } from "react-router-dom";

import { AppContext } from "./context/AppContext";

import router from "./routes";

const App = () => {
  const contextValue = useContext(AppContext);

  useEffect(() => {
    if (localStorage.getItem("userData") !== null) {
      localStorage.removeItem("userData");
    }
  }, []);

  const { setIsLogged, userData, isUserDataLoading, setIsUserDataLoading } =
    contextValue;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (!window.location.pathname.includes("login")) {
      setIsUserDataLoading(true);
    }
  }, []);

  if (sessionStorage.getItem("isLogged")) {
    setIsLogged(true);
  }

  useEffect(() => {
    if (userData.email === "info@permoniq.com") {
      let isEditing = false;
      let originalContent = "";
      let currentElement = null;

      const handleAltClick = (event) => {
        if (event.altKey && !isEditing) {
          event.preventDefault();
          currentElement = event.target;

          if (currentElement && currentElement.contentEditable !== "true") {
            originalContent = currentElement.innerText;
            currentElement.contentEditable = "true";
            currentElement.focus();
            isEditing = true;

            // Zakáže pointer events na stránce, aby tlačítka a odkazy nefungovaly
            document.body.style.pointerEvents = "none";
            currentElement.style.pointerEvents = "all"; // Povolit interakci s aktuálním prvkem
          }
        }
      };

      const handleEscKey = (event) => {
        if (event.key === "Escape" && isEditing) {
          try {
            if (currentElement) {
              currentElement.contentEditable = "false";
              currentElement.blur();
            }
          } catch (error) {
            console.error("Error during blur operation:", error);
          } finally {
            isEditing = false;
            currentElement = null;

            // Obnoví pointer events na stránce
            document.body.style.pointerEvents = "auto";
          }
        }
      };

      const handleBlur = () => {
        if (isEditing && currentElement) {
          currentElement.contentEditable = "false";
          isEditing = false;
          currentElement = null;

          // Obnoví pointer events na stránce
          document.body.style.pointerEvents = "auto";
        }
      };

      document.addEventListener("click", handleAltClick);
      document.addEventListener("keydown", handleEscKey);
      document.addEventListener("blur", handleBlur, true);

      // return () => {
      //   document.removeEventListener('click', handleAltClick);
      //   document.removeEventListener('keydown', handleEscKey);
      //   document.removeEventListener('blur', handleBlur, true);
      // };
      window.appContext = Object.freeze(contextValue);
    }

    // Dočasně přístupné - smazat!!!
  }, [userData.email, contextValue]);

  useEffect(() => {
    console.log(`version: ${process.env.REACT_APP_VERSION}\n`);
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
