import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

import SimpleAlert from "../components/SimpleAlert";
import ProgressTracker from "../components/ProgressTracker";

import axiosInstance from "../api/AxiosConfig";

import obecnyNavodLogo from "../img/logos/Obecný návod.svg";
import shoptetLogo from "../img/logos/Shoptet.svg";
import upgatesLogo from "../img/logos/Upgates.svg";
import shopifyLogo from "../img/logos/Shopify.svg";
import woocommerceLogo from "../img/logos/WooCommerce.svg";
import prestashopLogo from "../img/logos/PrestaShop.svg";
import eshopRychleLogo from "../img/logos/Eshop-rychle.svg";
import shopsysLogo from "../img/logos/Shopsys.svg";
import webarealLogo from "../img/logos/Webareal.svg";
import nodyLogo from "../img/logos/Nody.svg";

import useUserRegisterDetails from "../hooks/useUserRegisterDetails";

import ModalContactUs from "../components/ModalContactUs";
import ModalLoading from "../components/ModalLoading";
import Modal from "../components/Modal";
import InfoBoxItem from "../components/InfoBoxItem";

import sendEvent from "../utils/functions/sendEvent";

const UploadProducts = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const [feed, setFeed] = useState("");
  const [message, setMessage] = useState("");

  const {
    uuid,
    steps,
    isAdmin,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    isUserDataLoading,
    setIsUserDataLoading,
    showAdminModal,
    setShowAdminModal,
    infoItems,
    userData
  } = useContext(AppContext);

  const answerRefs = useRef([]);

  useUserRegisterDetails();

  useEffect(() => {
    sendEvent(`rg_${location.pathname.split("/")[3]}`);
  }, []);

  useEffect(() => {
    const fetchFeed = async () => {
      if (uuid) {
        try {
          const response = await axiosInstance.get(`shop/detail/${uuid}/`);
          const data = response.data;
          setFeed(data.feed);
        } catch (error) {
          if (!isAdmin) {
            console.error(error);
            navigate(`/${lng}/app/register-shop/`);
          }
        }
      }
    };
    fetchFeed();
  }, [uuid, navigate, lng, isAdmin]);

  let shoptetSteps = t("uploadProductsForm.shoptet.instructions", {
    returnObjects: true
  });
  let upgatesSteps = t("uploadProductsForm.upgates.instructions", {
    returnObjects: true
  });
  let shopifySteps = t("uploadProductsForm.shopify.instructions", {
    returnObjects: true
  });
  let woocommerceSteps = t("uploadProductsForm.woocommerce.instructions", {
    returnObjects: true
  });
  let prestashopSteps = t("uploadProductsForm.prestashop.instructions", {
    returnObjects: true
  });
  let eshopRychleSteps = t("uploadProductsForm.eshop-rychle.instructions", {
    returnObjects: true
  });
  let shopsysSteps = t("uploadProductsForm.shopsys.instructions", {
    returnObjects: true
  });
  let webarealSteps = t("uploadProductsForm.webareal.instructions", {
    returnObjects: true
  });

  let nodySteps = t("uploadProductsForm.nody.instructions", {
    returnObjects: true
  });

  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
  shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
  woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
  prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
  eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];
  webarealSteps = Array.isArray(webarealSteps) ? webarealSteps : [];
  nodySteps = Array.isArray(nodySteps) ? nodySteps : [];

  const validateUrl = (url) => {
    const urlPattern = /^https?:\/\/.*\..*/i;
    return urlPattern.test(url);
  };

  const handleInputChange = (e) => {
    setFeed(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUserDataLoading(true);
    if (feed && validateUrl(feed)) {
      const submittedData = {
        feed,
        status_create: "account"
      };
      try {
        const response = await axiosInstance.post(
          "validate-xml-feed/",
          submittedData
        );
        if (response.data.status === true) {
          try {
            await axiosInstance.put(`shop/update/${uuid}/`, submittedData);
            window.scrollTo(0, 0);
            navigate(`/${lng}/app/connect-google/`);
          } catch (error) {
            setIsUserDataLoading(false);
            showAlert(
              t("uploadProductsForm.errorMessages.updateFailedErrorMsg"),
              "danger"
            );
          }
        } else {
          setIsUserDataLoading(false);
          showAlert(
            t("uploadProductsForm.errorMessages.updateFailedErrorMsg"),
            "danger"
          );
        }
      } catch (error) {
        console.error(error);
        showAlert(
          t("uploadProductsForm.errorMessages.updateFailedErrorMsg"),
          "danger"
        );
      }
    } else {
      showAlert(
        t("uploadProductsForm.errorMessages.fillAllFieldsErrorMsg"),
        "danger"
      );
    }
  };

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  // Přizpůsobení labels v steps
  const localizedSteps = steps.map((step) => ({
    ...step,
    label: t(`${step.label}`),
    alertMessage: t(step.alertMessage)
  }));

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Nahrávání produktů - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-checked"><strong>1.</strong> {t('uploadProductsProgress.addShop')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-checked"><strong>2.</strong> {t('uploadProductsProgress.companyDetails')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))} className="is-active"><strong>3.</strong> {t('uploadProductsProgress.uploadProducts')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))}><strong>4.</strong> {t('uploadProductsProgress.connectGoogle')}</li>
                        <li onClick={() => showAlert(t("uploadProductsForm.contactSupport"))}><strong>5.</strong> {t('uploadProductsProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

      <ProgressTracker steps={localizedSteps} />
      <ModalContactUs setMessage={setMessage} />
      {isUserDataLoading && <ModalLoading message={message} />}

      <section className="section register">
        <article className="container">
          <h1 className="title">{t("uploadProductsTitle")}</h1>
          <form onSubmit={handleSubmit} autoComplete="off" className="form">
            <input type="hidden" id="uuid" value={uuid} />

            <div className="box">
              <p>
                <label htmlFor="feed">{t("uploadProductsForm.feedUrl")}</label>
                <input
                  type="text"
                  id="feed"
                  value={feed}
                  onChange={handleInputChange}
                  required
                />
              </p>

              <br />

              <p style={{ fontWeight: 600 }}>
                {t("uploadProductsForm.whereToFindFeed")}
              </p>
              <p>{t("uploadProductsForm.whereToFindFeedAnswer")}</p>

              <div className="faq faq--compact">
                <div
                  className={`faq__item ${
                    openIndices.includes(0) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(0)}
                >
                  <div className="common__tutor">
                    <img
                      className="logo__icon"
                      src={obecnyNavodLogo}
                      alt={t("uploadProductsForm.shoptet.name")}
                    />
                    <h3 className="faq__question">
                      {t("uploadProductsForm.generalInstructions")}
                    </h3>
                  </div>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[0] = el)}
                    style={{
                      maxHeight: openIndices.includes(0)
                        ? `${heights[0]}px`
                        : "0"
                    }}
                  >
                    <p>{t("uploadProductsForm.faqGeneralAnswer1")}</p>
                    {/* <p>
                                            {t('uploadProductsForm.faqGeneralAnswer2')}
                                        </p> */}
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(1) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(1)}
                >
                  <h3 className="faq__question">
                    <img
                      className="logo__icon"
                      src={shoptetLogo}
                      alt={t("uploadProductsForm.shoptet.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[1] = el)}
                    style={{
                      maxHeight: openIndices.includes(1)
                        ? `${heights[1] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {shoptetSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.shoptet.cz"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>,
                              <strong></strong>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(2) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(2)}
                >
                  <h3 className="faq__question">
                    <img
                      className="logo__icon"
                      src={upgatesLogo}
                      alt={t("uploadProductsForm.upgates.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer test`}
                    ref={(el) => (answerRefs.current[2] = el)}
                    style={{
                      maxHeight: openIndices.includes(2)
                        ? `${heights[2] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {upgatesSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.upgates.cz"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(3) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(3)}
                >
                  <h3 className="faq__question">
                    <img
                      className="logo__icon"
                      src={shopifyLogo}
                      alt={t("uploadProductsForm.shopify.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[3] = el)}
                    style={{
                      maxHeight: openIndices.includes(3)
                        ? `${heights[3] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {shopifySteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.shopify.com/"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(4) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(4)}
                >
                  <h3 className="faq__question">
                    <img
                      className="logo__icon"
                      src={woocommerceLogo}
                      alt={t("uploadProductsForm.woocommerce.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[4] = el)}
                    style={{
                      maxHeight: openIndices.includes(4)
                        ? `${heights[4] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {woocommerceSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(5) ? "is-open" : ""
                  }`}
                  onClick={() => handleToggle(5)}
                >
                  <h3 className="faq__question">
                    <img
                      className="logo__icon"
                      src={prestashopLogo}
                      alt={t("uploadProductsForm.prestashop.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[5] = el)}
                    style={{
                      maxHeight: openIndices.includes(5)
                        ? `${heights[5] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {prestashopSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://prestashop.com/"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div
                  className={`faq__item ${
                    openIndices.includes(6) ? "is-open" : ""
                  }`}
                >
                  <h3 className="faq__question" onClick={() => handleToggle(6)}>
                    <img
                      className="logo__icon"
                      src={eshopRychleLogo}
                      alt={t("uploadProductsForm.eshop-rychle.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[6] = el)}
                    style={{
                      maxHeight: openIndices.includes(6)
                        ? `${heights[6] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {eshopRychleSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.eshop-rychle.cz/"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                <div
                  className={`faq__item ${
                    openIndices.includes(7) ? "is-open" : ""
                  }`}
                >
                  <h3 className="faq__question" onClick={() => handleToggle(7)}>
                    <img
                      className="logo__icon"
                      src={shopsysLogo}
                      alt={t("uploadProductsForm.shopsys.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[7] = el)}
                    style={{
                      maxHeight: openIndices.includes(7)
                        ? `${heights[7] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {shopsysSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.shopsys.cz/"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                <div
                  className={`faq__item ${
                    openIndices.includes(8) ? "is-open" : ""
                  }`}
                >
                  <h3 className="faq__question" onClick={() => handleToggle(8)}>
                    <img
                      className="logo__icon"
                      src={webarealLogo}
                      alt={t("uploadProductsForm.webareal.name")}
                    />
                  </h3>
                  <div
                    className={`faq__answer`}
                    ref={(el) => (answerRefs.current[8] = el)}
                    style={{
                      maxHeight: openIndices.includes(8)
                        ? `${heights[8] - 30}px`
                        : "0"
                    }}
                  >
                    <ol>
                      {webarealSteps.map((step, index) => (
                        <li key={index}>
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href="https://www.webareal.cz/"
                                target="_blank"
                                className="link"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              ></a>
                            ]}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              <div
                className={`faq__item ${
                  openIndices.includes(9) ? "is-open" : ""
                }`}
              >
                <h3 className="faq__question" onClick={() => handleToggle(9)}>
                  <img
                    className="logo__icon"
                    src={nodyLogo}
                    alt={t("uploadProductsForm.nody.name")}
                  />
                </h3>
                <div
                  className={`faq__answer`}
                  ref={(el) => (answerRefs.current[9] = el)}
                  style={{
                    maxHeight: openIndices.includes(9)
                      ? `${heights[9] - 30}px`
                      : "0"
                  }}
                >
                  <ol>
                    {nodySteps.map((step, index) => (
                      <li key={index}>
                        <Trans
                          i18nKey={step}
                          components={[
                            <a
                              href="https://nody.cz/"
                              target="_blank"
                              className="link"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            ></a>
                          ]}
                        />
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
              </div>
            </div>

            <div className="register__submit">
              <p>
                <button type="submit" className="btn">
                  {t("uploadProductsForm.submit")}
                </button>
              </p>
            </div>
          </form>
        </article>
      </section>
      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
    </>
  );
};

export default UploadProducts;
