import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis, // Import YAxis
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "../css/ProductsGraph.module.css";
import { formatDate } from "../utils/functions/formatDate";
import { useParams } from "react-router-dom";
import CustomActiveDot from "./CustomActiveDot";
import formatNumber from "../utils/functions/formatNumber";

const ProductsGraph = ({ data, onDayClick }) => {
  const { lng } = useParams();

  const handleClick = (e) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      const clickedData = e.activePayload[0].payload;
      if (onDayClick) onDayClick(clickedData);
    }
  };

  // Funkce pro výpočet maximální horní hranice Y osy
  // Přidá např. 20% k maximální hodnotě dat pro vytvoření prostoru
  const calculateDomainMax = (dataMax) => {
    if (typeof dataMax !== "number" || dataMax === 0) {
      return 10; // Pokud není max hodnota nebo je 0, nastavíme nějaké malé maximum (např. 10)
    }
    // Přidáme 20% padding a zaokrouhlíme nahoru na celé číslo
    return Math.ceil(dataMax * 1.2);
  };

  return (
    <div className={styles.container}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          onClick={handleClick}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          style={{ cursor: "pointer" }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => formatDate(lng, date)}
            interval={"preserveEnd"}
            tickMargin={10}
            padding={{ right: 20 }}
          />
          <YAxis
            domain={["auto", calculateDomainMax]}
            tickFormatter={(value) => {
              return formatNumber(value, undefined, 0);
            }}
          />
          <Tooltip
            offset={100}
            cursor={{ strokeWidth: 3, strokeDasharray: 10 }}
            labelFormatter={(label) => formatDate(lng, label)}
            formatter={(value, name) => {
              const formattedValue = formatNumber(value, undefined, 0);
              return [formattedValue, name];
            }}
          />

          <Line
            strokeWidth={4}
            type="monotone"
            name="Aktivní"
            dataKey="approved_count"
            stroke="#22E06C"
            legendType="circle"
            activeDot={<CustomActiveDot />}
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Končící platnost"
            dataKey="suspended_count"
            stroke="#ED7200"
            legendType="circle"
            activeDot={<CustomActiveDot />}
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Čekající na vyřízení"
            dataKey="pending_count"
            stroke="#0000FF"
            legendType="circle"
            activeDot={<CustomActiveDot />}
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Zamítnuto"
            dataKey="rejected_count"
            stroke="#FF0000"
            legendType="circle"
            activeDot={<CustomActiveDot />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductsGraph;
