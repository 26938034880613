import React, { useState, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "../css/FeedTutor.module.css";
import CloseBtn from "./CloseBtn";

// Import log ikon
import obecnyNavodLogo from "../img/logos/Obecný návod.svg";
import shoptetLogo from "../img/logos/Shoptet.svg";
import upgatesLogo from "../img/logos/Upgates.svg";
import shopifyLogo from "../img/logos/Shopify.svg";
import woocommerceLogo from "../img/logos/WooCommerce.svg";
import prestashopLogo from "../img/logos/PrestaShop.svg";
import eshopRychleLogo from "../img/logos/Eshop-rychle.svg";
import shopsysLogo from "../img/logos/Shopsys.svg";
import webarealLogo from "../img/logos/Webareal.svg"; // Nový import
import nodyLogo from "../img/logos/Nody.svg"; // Přidaný import pro Nody

const FeedTutor = ({ onClose }) => {
  const { t } = useTranslation();

  // Načteme instrukce z překladů
  let generalSteps = [t("uploadProductsForm.faqGeneralAnswer1")];
  let shoptetSteps = t("uploadProductsForm.shoptet.instructions", {
    returnObjects: true,
  });
  let upgatesSteps = t("uploadProductsForm.upgates.instructions", {
    returnObjects: true,
  });
  let shopifySteps = t("uploadProductsForm.shopify.instructions", {
    returnObjects: true,
  });
  let woocommerceSteps = t("uploadProductsForm.woocommerce.instructions", {
    returnObjects: true,
  });
  let prestashopSteps = t("uploadProductsForm.prestashop.instructions", {
    returnObjects: true,
  });
  let eshopRychleSteps = t("uploadProductsForm.eshop-rychle.instructions", {
    returnObjects: true,
  });
  let shopsysSteps = t("uploadProductsForm.shopsys.instructions", {
    returnObjects: true,
  });
  let webarealSteps = t("uploadProductsForm.webareal.instructions", {
    returnObjects: true,
  }); // Načtení instrukcí pro Webareal
  let nodySteps = t("uploadProductsForm.nody.instructions", {
    returnObjects: true,
  }); // Načtení instrukcí pro Nody

  // Ošetření, aby šlo o pole
  generalSteps = Array.isArray(generalSteps) ? generalSteps : [generalSteps];
  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
  shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
  woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
  prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
  eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];
  shopsysSteps = Array.isArray(shopsysSteps) ? shopsysSteps : [];
  webarealSteps = Array.isArray(webarealSteps) ? webarealSteps : [];
  nodySteps = Array.isArray(nodySteps) ? nodySteps : [];

  // Sestavení pole FAQ položek
  const faqItems = [
    {
      logo: obecnyNavodLogo,
      altText: t("uploadProductsForm.generalInstructions"),
      steps: generalSteps,
    },
    {
      logo: shoptetLogo,
      altText: t("uploadProductsForm.shoptet.name"),
      steps: shoptetSteps,
      link: "https://www.shoptet.cz",
    },
    {
      logo: upgatesLogo,
      altText: t("uploadProductsForm.upgates.name"),
      steps: upgatesSteps,
      link: "https://www.upgates.cz",
    },
    {
      logo: shopifyLogo,
      altText: t("uploadProductsForm.shopify.name"),
      steps: shopifySteps,
      link: "https://www.shopify.com",
    },
    {
      logo: woocommerceLogo,
      altText: t("uploadProductsForm.woocommerce.name"),
      steps: woocommerceSteps,
      // U WooCommerce se nevyužívá odkaz
    },
    {
      logo: prestashopLogo,
      altText: t("uploadProductsForm.prestashop.name"),
      steps: prestashopSteps,
      link: "https://prestashop.com",
    },
    {
      logo: eshopRychleLogo,
      altText: t("uploadProductsForm.eshop-rychle.name"),
      steps: eshopRychleSteps,
      link: "https://www.eshop-rychle.cz/",
    },
    {
      logo: shopsysLogo,
      altText: t("uploadProductsForm.shopsys.name"),
      steps: shopsysSteps,
      link: "https://www.shopsys.cz/",
    },
    {
      logo: webarealLogo,
      altText: t("uploadProductsForm.webareal.name"),
      steps: webarealSteps,
      link: "https://www.webareal.cz/",
    },
    // Nová položka pro Nody
    {
      logo: nodyLogo,
      altText: t("uploadProductsForm.nody.name"),
      steps: nodySteps,
      link: "https://nody.cz/",
    },
  ];

  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const answerRefs = useRef([]);

  const handleToggle = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Zjištění výšky jednotlivých odpovědí
  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prev) => {
      const newHeights = { ...prev };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  return (
    <div className={styles.container} onClick={onClose}>
      <CloseBtn onClick={onClose} />
      <div className={styles.modal_box} onClick={(e) => e.stopPropagation()}>
        <h2>
          {t(
            "uploadProductsForm.feedInstructionsTitle",
            "Jak nahrát XML feed?"
          )}
        </h2>
        <div className={styles.textBox}>
          {faqItems.map((item, index) =>
            index === 0 ? (
              <div
                key={index}
                className={`faq__item ${styles.feedTutorItem} ${
                  openIndices.includes(index) ? "is-open" : ""
                }`}
                onClick={() => handleToggle(index)}
              >
                <h3
                  className={styles.feedTutorQuestion}
                  style={{
                    display: "flex",
                    paddingBottom: "8px",
                    paddingTop: "4px",
                  }}
                >
                  <img
                    className={styles.logoIcon}
                    src={item.logo}
                    alt={item.altText}
                  />
                  {item.altText ===
                    t("uploadProductsForm.generalInstructions") && (
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      {item.altText}
                    </div>
                  )}
                </h3>
                <div
                  className={styles.feedTutorAnswer}
                  ref={(el) => (answerRefs.current[index] = el)}
                  style={{
                    maxHeight: openIndices.includes(index)
                      ? `${heights[index]}px`
                      : "0",
                  }}
                >
                  <p>{item.steps[0]}</p>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={`faq__item ${styles.feedTutorItem} ${
                  openIndices.includes(index) ? "is-open" : ""
                }`}
                onClick={() => handleToggle(index)}
              >
                <h3 className={styles.feedTutorQuestion}>
                  <img
                    className={styles.logoIcon}
                    src={item.logo}
                    alt={item.altText}
                  />
                </h3>
                <div
                  className={styles.feedTutorAnswer}
                  ref={(el) => (answerRefs.current[index] = el)}
                  style={{
                    maxHeight: openIndices.includes(index)
                      ? `${heights[index]}px`
                      : "0",
                  }}
                >
                  <ol>
                    {item.steps.map((step, stepIndex) => (
                      <li key={stepIndex}>
                        {item.link ? (
                          <Trans
                            i18nKey={step}
                            components={[
                              <a
                                href={item.link}
                                target="_blank"
                                className={"link"}
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              />,
                              <strong></strong>,
                            ]}
                          />
                        ) : (
                          step
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedTutor;
