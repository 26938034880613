import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import formatNumber from "../utils/functions/formatNumber";

import {
  LineChart,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import { AppContext } from "../context/AppContext";

import { FaGear } from "react-icons/fa6";

import { checkBilling } from "../utils/axios_functions/checkBiling";

import axiosInstance from "../api/AxiosConfig";

import SimpleAlert from "./SimpleAlert";
import ModalInfo from "./ModalInfo";
import ModalWelcome from "./ModalWelcome";

import dangerIcon from "../img/icons/danger.svg";
import googleIcon from "../img/login-google.svg";
import userIcon from "../img/icons/user.svg";
import basketIcon from "../img/icons/basket.svg";
import documentIcon from "../img/icons/document.svg";
import productsIcon from "../img/icons/products.svg";
import indicatorIcon from "../img/icons/indicator.svg";
import growIcon from "../img/icons/grow.svg";
import clickIcon from "../img/icons/click.svg";
import turnonIcon from "../img/icons/turnon.svg";
import thumbIcon from "../img/icons/thumb.svg";
import feedIcon from "../img/icons/feed.svg";
import clockIcon from "../img/icons/clock.svg";
import { ReactSVG } from "react-svg";
import tooltipInfoIcon from "../img/tooltip.svg";
import target from "../img/icons/target.svg";

import useUserAppDetails from "../hooks/useUserAppDetails";

import styles from "../css/AppDashboard.module.css";
import styleForInfoModal from "../css/ModalInfo.module.css";

import CapmaignStatusBar from "./progressBars/CampaignStatusBar";

import sendEvent from "../utils/functions/sendEvent";
import { formatDate } from "../utils/functions/formatDate";

const AppDashboard = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const { t } = useTranslation();

  const keyPointsOfLearningFaseTitle = t(
    "appDashboard.keyPointsOfLearningFaseTitle",
    { returnObjects: true }
  );

  const [shopForDisplay, setShopForDisplay] = useState({}); // Ukládá se zde aktuální obchod, podle jehož stavu se vykreslují některé elementy
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHowToStart, setShowHowToStart] = useState(false);

  const [totalExpenses, setTotalExpenses] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [cos, setCos] = useState({ value: 0, type: "Percent" });
  const [generatedRevenue, setGeneratedRevenue] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [assistedRevenue, setAssistedRevenue] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [visitorCount, setVisitorCount] = useState({
    value: 0,
    type: "Number",
  });
  const [clickCount, setClickCount] = useState({ value: 0, type: "Number" });
  const [orderCount, setOrderCount] = useState({ value: 0, type: "Number" });
  const [conversionRatio, setConversionRatio] = useState({
    value: 0,
    type: "Percent",
  });

  const [totalExpenses30, setTotalExpenses30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [cos30, setCos30] = useState({ value: 0, type: "Percent" });
  const [generatedRevenue30, setGeneratedRevenue30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [assistedRevenue30, setAssistedRevenue30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [visitorCount30, setVisitorCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [clickCount30, setClickCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [orderCount30, setOrderCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [conversionRatio30, setConversionRatio30] = useState({
    value: 0,
    type: "Percent",
  });

  const [dailyStats, setDailyStats] = useState([]);

  useUserAppDetails();

  const {
    userData,
    uuid,
    setAppContentType,
    setActiveItem,
    pno,
    toLocale,
    statsCurrency,
    selectedShop,
    getCurrency,
    alertMessage,
    isAlertVisible,
    showAlert,
    closeAlert,
    setShowArrow,
    setIsUserDataLoading,
    showWelcomeModal,
  } = useContext(AppContext);

  useEffect(() => {
    sendEvent("app_dashboard");
  }, []);

  useEffect(() => {
    if (uuid) {
      retrieveGraphData();
      retrieveGraphData30Days();
      retrieveDailyStats();
    }
  }, [uuid]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setShopForDisplay(
        userData?.shops?.find((oneShop) => oneShop.uuid === uuid)
      );
    }
  }, [uuid, shopForDisplay]);

  const onRedirect = (where, isApp = true) => {
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/${where}/`);

    if (isApp) {
      setActiveItem(where);
      setAppContentType(where);
    }
  };

  const retrieveGraphData = async () => {
    try {
      const currentDate = new Date();
      const sevenDaysAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ); // Posledních 7 dní

      const retrieveGraphDataResponse = await axiosInstance.post(
        `shop/stats/summary/${uuid}/`,
        {
          start_date: sevenDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
          // campaign_type: "google-pmax"
        }
      );

      const data = retrieveGraphDataResponse.data;

      // Nastavíme získaná data do stavových proměnných, kde čísla převedeme pomocí formatNumber
      setTotalExpenses({
        ...data.totalExpenses,
        value: formatNumber(data.totalExpenses.value),
      });
      setCos({
        ...data.cos,
        value: formatNumber(data.cos.value),
      });
      setGeneratedRevenue({
        ...data.generatedRevenue,
        value: formatNumber(data.generatedRevenue.value),
      });
      setAssistedRevenue({
        ...data.assistedRevenue,
        value: formatNumber(data.assistedRevenue.value),
      });
      setVisitorCount({
        ...data.visitorCount,
        value: formatNumber(data.visitorCount.value),
      });
      setClickCount({
        ...data.clickCount,
        value: formatNumber(data.clickCount.value),
      });
      setOrderCount({
        ...data.orderCount,
        value: formatNumber(data.orderCount.value),
      });
      setConversionRatio({
        ...data.conversionRatio,
        value: formatNumber(data.conversionRatio.value),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const retrieveGraphData30Days = async () => {
    try {
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      ); // Posledních 30 dní

      const retrieveGraphData30DaysResponse = await axiosInstance.post(
        `shop/stats/summary/${uuid}/`,
        {
          start_date: thirtyDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
          // campaign_type: "google-pmax"
        }
      );

      const data = retrieveGraphData30DaysResponse.data;

      // Nastavíme získaná data do stavových proměnných pro 30 dní
      setTotalExpenses30({
        ...data.totalExpenses,
        value: formatNumber(data.totalExpenses.value),
      });
      setCos30({
        ...data.cos,
        value: formatNumber(data.cos.value),
      });
      setGeneratedRevenue30({
        ...data.generatedRevenue,
        value: formatNumber(data.generatedRevenue.value),
      });
      setAssistedRevenue30({
        ...data.assistedRevenue,
        value: formatNumber(data.assistedRevenue.value),
      });
      setVisitorCount30({
        ...data.visitorCount,
        value: formatNumber(data.visitorCount.value),
      });
      setClickCount30({
        ...data.clickCount,
        value: formatNumber(data.clickCount.value),
      });
      setOrderCount30({
        ...data.orderCount,
        value: formatNumber(data.orderCount.value),
      });
      setConversionRatio30({
        ...data.conversionRatio,
        value: formatNumber(data.conversionRatio.value),
      });
      if (data.totalExpenses.value === 0) {
        setShowHowToStart(true);
      } else {
        setShowHowToStart(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const retrieveDailyStats = async () => {
    try {
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      );

      const retrieveDailyStatsResponse = await axiosInstance.post(
        `shop/stats/daily/${uuid}/`,
        {
          start_date: thirtyDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
          // campaign_type: "google-pmax"
        }
      );

      const updatedStats = retrieveDailyStatsResponse.data.map((item) => ({
        ...item,
        total_clicks: Number(item.total_clicks.toFixed(2)),
        total_cost: Number(item.total_cost.toFixed(2)),
        total_visitors: Number(item.total_visitors.toFixed(2)),
        total_assisted_conversions: Number(
          item.total_assisted_conversions.toFixed(2)
        ),
        total_conversions: Number(item.total_conversions.toFixed(2)),
        total_assisted_conversion_value: Number(
          item.total_assisted_conversion_value.toFixed(2)
        ),
        total_conversion_value: Number(item.total_conversion_value.toFixed(2)),
        conversion_rate: Number(
          ((item.total_conversions / item.total_visitors) * 100).toFixed(2)
        ), // již zaokrouhleno
      }));

      setDailyStats(updatedStats);
    } catch (error) {
      console.log("Daily stats error: ", error);
    }
  };

  const toggleModal = (targetId) => {
    setIsModalOpen(!isModalOpen);

    if (!isModalOpen && targetId) {
      setTimeout(() => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          // Posun na zvolený element
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
          // Přidá CSS třídu s pulzující animací
          targetElement.classList.add("pulse__border__no__outline");

          // Po 3 sekundách třídu odebere
          setTimeout(() => {
            targetElement.classList.remove("pulse__border__no__outline");
          }, 3000); // 3000 ms = 3 sekundy
        }
      }, 100); // Zpoždění pro načtení modalu
    }
  };

  // const handleShowArrowOnClick = () => {
  //   setShowArrow(true);

  //   // Po 2 vteřinách automaticky skryjeme šipku
  //   setTimeout(() => {
  //     setShowArrow(false);
  //   }, 2000); // 2000 ms = 2 vteřiny
  // };

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {showWelcomeModal && <ModalWelcome />}
      <article className="app-content app-content--dashboard">
        {/* <div className="item item--danger">
      <span className="item__ico">
        <span className="icon icon--danger">
          <img src={dangerIcon} alt="danger" className="icon__svg" />
        </span>
      </span>
      <span className="item__title">Váš účet je neaktivní, prosím dobijte si kredit.</span>
      <span className="item__control">
        <a href="#" className="btn">Dobít kredit</a>
      </span>
    </div> */}

        {showHowToStart && (
          <div className="box">
            <h2>{t("appDashboard.startWithPermoniq")}</h2>
            {/* <div className="progress progress--app">
            <div className="container">
              <ul>
                <li onMouseEnter={() => setShowArrow(true)} onMouseLeave={() => setShowArrow(false)} onClick={handleShowArrowOnClick} style={{ whiteSpace: "nowrap" }}>
                  <strong>1.</strong>{t("appDashboard.registerYourShop")}
                </li>
                <li onClick={() => onRedirect("settings")} style={{ whiteSpace: "nowrap" }}>
                  <strong>2.</strong> {t("appDashboard.basicSetting")}
                </li>
                <li onClick={() => onRedirect("credit")} style={{ whiteSpace: "nowrap" }} className={`${(shopForDisplay?.campaign?.length === 0) && "is-inactive"}`}>
                  <strong>3.</strong> {t("appDashboard.topUpCredit")}
                </li>
                <li onClick={() => onRedirect("campaigns")} style={{ whiteSpace: "nowrap" }} className={`${(shopForDisplay?.campaign?.length === 0) && "is-inactive"}`}>
                  <strong>4.</strong> {t("appDashboard.startCampaign")}
                </li>
              </ul>
            </div>
          </div> */}

            <div className="box__note" style={{ color: "black" }}>
              <h3>{t("appDashboard.howToStart")}</h3>
              <p>
                <Trans
                  i18nKey="appDashboard.topUpCreditInfo"
                  components={[
                    <a
                      onClick={() => onRedirect("credit")}
                      className={styleForInfoModal.anchor_to_credit}
                    ></a>,
                  ]}
                />
              </p>
            </div>
          </div>
        )}

        <div className="app-content__row">
          {/* <div className="box">
        <h2>Kampaně</h2>
        <div className="box__note">
          <p>Přehled vašich kampaní v PermoniQ</p>
        </div>
        <h4>Zapnuté reklamní formáty</h4>
        <div className="campaign">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Google shopping (PLA)</span>
        </div>
        <div className="campaign">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Dynamické textové reklamy (DSA)</span>
        </div>
        <h4>Doporučujeme také zapnout</h4>
        <div className="campaign campaign--on">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Performance max</span>
        </div>
        <br />
        <div className="campaign campaign--on">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Performance max</span>
        </div>
      </div> */}

          <div className="box learning__box">
            {orderCount30.value > 30 ? (
              <>
                <h2>{t("appDashboard.afterLeatningFaseTitle")}</h2>
                <div className="box__note box__note--bubble">
                  <p>{t("appDashboard.afterLearningFaseInfo")}</p>
                </div>
              </>
            ) : (
              <>
                <div className={styles.learnBox}>
                  <h2 className={styles.heading}>
                    {t("appDashboard.learningFase")}
                  </h2>
                  {/* <div className={styles.iconBox}>
                    <span className={styles.iconText}>Učení</span>
                    <FaGear className={styles.gear} />
                  </div> */}
                </div>
                <div className="box__note box__note--bubble">
                  <h3>{t("appDashboard.whatIsLearningFase")}</h3>
                  <p>{t("appDashboard.learningFaseInfo")}</p>
                  <ul
                    style={{
                      paddingLeft: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {Object.keys(keyPointsOfLearningFaseTitle).map(
                      (key, index) => {
                        const { title, desc } =
                          keyPointsOfLearningFaseTitle[key];
                        return (
                          <li key={index}>
                            {title}
                            <br />- {desc}
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <p>{t("appDashboard.afterLearningFase")}</p>
                </div>
              </>
            )}
            <div className="campaign campaign--status">
              <span className="campaign__ico">
                <img src={googleIcon} alt="google" />
              </span>
              <span className="campaign__title">Performance max</span>
              <span className="campaign__info">
                <em>{t("appDashboard.conversionCount")}</em>
              </span>
              {/* <span className={`campaign__status ${((orderCount30.value > 0 && orderCount30.value < 30) && "is-partial") || (orderCount30.value >= 30 && "is-full")}`}><em>{orderCount30.value}</em></span> */}
              <CapmaignStatusBar value={orderCount30.value}>
                {formatNumber(orderCount30.value, undefined, 0)}
              </CapmaignStatusBar>
            </div>
            {/* <div className="campaign campaign--status">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Dynamické textové reklamy</span>
          <span className="campaign__status is-full"><em>30</em></span>
        </div>
        <div className="campaign campaign--status">
          <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
          <span className="campaign__title">Dynamické produktové reklamy</span>
          <span className="campaign__status is-partial"><em>15</em></span>
        </div> */}
          </div>
        </div>

        {/* <div className="box">
          <h2>{t("appDashboard.conditionOfMeasurment")} <small>{t("appDashboard.last48Hours")}</small></h2>
          <ul className="indicator ">
      
            <li className={`indicator__item ${visitorCount.value > 0 ? "is-success" : "is-danger"}`}>
              <span className="icon icon--document">
                <ReactSVG src={userIcon} style={{ fill: visitorCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
              {t("appDashboard.users")}
              <span className="icon icon--indicator">
                <ReactSVG src={indicatorIcon} style={{ fill: visitorCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
            </li>
            <li className={`indicator__item ${orderCount.value > 0 ? "is-success" : "is-danger"}`}>
              <span className="icon icon--document">
                <ReactSVG src={documentIcon} style={{ fill: orderCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
              {t("appDashboard.orders")}
              <span className="icon icon--indicator">
                <ReactSVG src={indicatorIcon} style={{ fill: orderCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
            </li>
            <li className={`indicator__item ${orderCount.value > 0 ? "is-success" : "is-danger"}`}>
              <span className="icon icon--products">
                <ReactSVG src={productsIcon} style={{ fill: orderCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
              {t("appDashboard.soldProducts")}
              <span className="icon icon--indicator">
                <ReactSVG src={indicatorIcon} style={{ fill: orderCount.value > 0 ? "#22E06C" : "#D30000" }} />
              </span>
            </li>
          </ul>
        </div> */}

        <div className="box">
          <h2>
            {t("appDashboard.basicStatistics")}{" "}
            <small onClick={toggleModal} style={{ cursor: "pointer" }}>
              {t("appDashboard.last30Days")}
            </small>
          </h2>
          <div className="row">
            <div className="col col--2">
              <p className="graph">
                <span className="graph__legend">
                  {/* <em><span className="graph__point graph__point--1"></span> Výdaje</em>
              <em><span className="graph__point graph__point--3"></span> Obrat</em> */}
                </span>
              </p>
              <div>
                <ResponsiveContainer width="100%" height={300}>
                  {dailyStats.length > 0 ? (
                    <LineChart data={dailyStats}>
                      <CartesianGrid
                        strokeDasharray="0"
                        strokeWidth={2}
                        vertical={false}
                      />
                      <XAxis
                        dataKey="date"
                        style={{ fontSize: 10 }}
                        interval={"equidistantPreserveStart"}
                        tickFormatter={(date) => formatDate(lng, date)}
                        minTickGap={50}
                        tickMargin={5}
                      />
                      <YAxis
                        style={{ fontSize: 10 }}
                        tickFormatter={(value) => toLocale(value)}
                      />
                      <Tooltip
                        labelFormatter={(label) => formatDate(lng, label)}
                        formatter={(value) =>
                          `${formatNumber(value)} ${getCurrency(
                            selectedShop.currency
                          )}`
                        }
                        offset={50}
                      />
                      <Legend wrapperStyle={{ bottom: -20 }} />
                      <Line
                        type="monotone"
                        legendType="circle"
                        dataKey="total_conversion_value"
                        strokeWidth={2}
                        stroke="#D455CF"
                        name="Obrat"
                      />
                      <Line
                        type="monotone"
                        legendType="circle"
                        dataKey="total_cost"
                        strokeWidth={2}
                        stroke="#2768B5"
                        name="Výdaje"
                      />
                    </LineChart>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        lineHeight: "300px",
                        fontSize: "18px",
                        color: "#999",
                      }}
                    >
                      No data
                    </div>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col col--2">
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--7 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("order__count__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--7 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#f2edf9",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--basket">
                    {/* <img src={basketIcon} alt="basket" className="icon__svg" /> */}
                    <ReactSVG src={basketIcon} />
                  </span>
                </span>
                <span className="info__title">
                  {t("appDashboard.orderCount")}
                </span>
                <span className="info__value">
                  {formatNumber(orderCount30.value, undefined, 0)}
                </span>
              </div>
              <br />
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--5 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("pno__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--5 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#e5f7f8",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--grow">
                    {/* <img src={growIcon} alt="grow" className="icon__svg" /> */}
                    <ReactSVG src={target} />
                  </span>
                </span>
                <span className="info__title">PNO</span>
                <span className="info__value">
                  <span
                    title={`PNO`}
                    // style={{ color: cos30.value > pno ? 'red' : 'black' }}
                  >
                    {cos30.value} %
                  </span>
                  &nbsp;/&nbsp;
                  <span
                    title={t("appDashboard.targetPNO")}
                    style={{ cursor: "pointer" }}
                  >
                    {pno} %
                  </span>
                </span>
              </div>
              <br />
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--6 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("click__count__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--6 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#fdf1e6",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--click">
                    {/* <img src={clickIcon} alt="click" className="icon__svg" /> */}
                    <ReactSVG src={clickIcon} />
                  </span>
                </span>
                <span className="info__title">
                  {t("appDashboard.clickCount")}
                </span>
                <span className="info__value">
                  {formatNumber(clickCount30.value, undefined, 0)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="app-content__row">
          <div className="box how_grow_campaigns_box">
            <h2>{t("appDashboard.tipsForGrowth")}</h2>
            <div className="box__note">
              <p>{t("appDashboard.basicRules")}</p>
            </div>
            <ul className="box__list">
              <li>
                <span className="icon icon--turnon">
                  <img src={turnonIcon} alt="turnon" className="icon__svg" />
                </span>
                {t("appDashboard.firstRule")}
              </li>
              <li>
                <span className="icon icon--thumb">
                  <img src={thumbIcon} alt="thumb" className="icon__svg" />
                </span>
                {t("appDashboard.secondRule")}
              </li>
              <li>
                <span className="icon icon--feed">
                  <img src={feedIcon} alt="feed" className="icon__svg" />
                </span>
                {t("appDashboard.thirdRule")}
              </li>
              <li>
                <span className="icon icon--clock">
                  <img src={clockIcon} alt="clock" className="icon__svg" />
                </span>
                {t("appDashboard.fourthRule")}
              </li>
            </ul>
          </div>
          {/* <div className="box">
        <h2>Jak klesá PNI v čase?</h2>
        <div className="box__note">
          <p><strong>PNO = Podíl nákladů na obratu.</strong></p>
          <p>Čím více dat mají kampaně k dispozici, tím se také zlepšuje jejich výkonnost. podívejte se na následující graf, jak e-shopům průměrně klesá PNO v prvních 12 měsících od prvního spuštění kampaní.</p>
        </div>
        <br />
        <p>
          <img src="/img/placeholder/graph-5.png" alt="graph" />
        </p>
      </div> */}
        </div>

        <div className="box">
          <h2>{t("appDashboard.nameOfProducts")}</h2>
          <div className="box__note">
            <h3 style={{ color: "black" }}>
              {t("appDashboard.productsToPerfection")}
            </h3>
            <p>
              <Trans
                i18nKey="appDashboard.productNamingIntroduction"
                components={[<strong></strong>]}
              />
            </p>
            <h3 style={{ color: "black" }}>
              {t("appDashboard.namingRecommendationTitle")}
            </h3>
            <p>{t("appDashboard.namingRecommendation")}</p>
            <ul>
              <li>{t("appDashboard.namingRecommendationPartTwo")}</li>
              <ul>
                <li>{t("appDashboard.importanceOfQuantity")}</li>
              </ul>
            </ul>
            <h3 style={{ color: "black" }}>
              {t("appDashboard.correctNamingExamplesTitle")}
            </h3>
            <ul>
              <li>{t("appDashboard.correctNamingExamples.0")}</li>
              <li>{t("appDashboard.correctNamingExamples.1")}</li>
              <li>{t("appDashboard.correctNamingExamples.2")}</li>
              <li>{t("appDashboard.correctNamingExamples.3")}</li>
              <li>{t("appDashboard.correctNamingExamples.4")}</li>
              <li>{t("appDashboard.correctNamingExamples.5")}</li>
              <li>{t("appDashboard.correctNamingExamples.6")}</li>
              <li>{t("appDashboard.correctNamingExamples.7")}</li>
            </ul>
            <p>{t("appDashboard.namingConclusion")}</p>
          </div>
        </div>

        <p className="link" onClick={toggleModal} style={{ cursor: "pointer" }}>
          {t("statisticsModal.modalLink")}
        </p>
        <ModalInfo isOpen={isModalOpen} toggleModal={toggleModal} t={t} />
      </article>
    </>
  );
};

export default AppDashboard;
