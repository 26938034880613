import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axiosInstance from "../api/AxiosConfig";
import styles from "../css/Users.module.css";
import ModalUsers from "../components/ModalUsers";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  const { setIsUserDataLoading } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");

  // Nový stav pro řazení: "email" nebo "registration"
  const [sortOption, setSortOption] = useState("email");

  // Klíče pro filtrování – nyní zahrnují i „nenastaven“
  const [selectedStages, setSelectedStages] = useState({
    "nenastaven": true,
    "0.1": false,
    "10": false,
  });

  // Nový stav pro filtrování registrovaných uživatelů v posledním týdnu
  const [registeredLastWeek, setRegisteredLastWeek] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [shops, setShops] = useState([]);
  const [statusText, setStatusText] = useState("Načítám uživatele...");

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    users.forEach((user) => {
      console.log(user.registered_at)
    })
  }, [users])

  // Filtrování dle emailu, hodnoty user_value, data registrace a následné řazení podle sortOption
  // Filtrování dle emailu, hodnoty user_value, data registrace a následné řazení podle sortOption
  useEffect(() => {
    // Výpočet data před 7 dny (včetně normalizace na půlnoc pro konzistentní porovnání)
    const oneWeekAgo = new Date();
    oneWeekAgo.setHours(0, 0, 0, 0); // Nastavíme na začátek dnešního dne
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Posuneme o 7 dní zpět

    const filtered = users
      .filter((user) => {
        // --- Stávající filtry (email, stage) ---
        if (!user.email.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
        const stage = getUserStage(user.user_value);
        if (!selectedStages[stage]) return false;
        // --- Konec stávajících filtrů ---


        // --- Nová logika pro filtr "Do 7 dnů" ---
        let registrationDate;
        try {
            // Pokusíme se vytvořit platné datum z API
            registrationDate = new Date(user.registered_at);
            // Zkontrolujeme, zda je datum platné
            if (isNaN(registrationDate.getTime())) {
                console.warn(`Neplatné datum registrace pro uživatele ${user.email}: ${user.registered_at}`);
                return false; // Uživatel s neplatným datem nesplňuje žádnou podmínku
            }
            // Normalizujeme datum registrace na půlnoc pro správné porovnání dnů
            registrationDate.setHours(0, 0, 0, 0);
        } catch (e) {
             console.warn(`Chyba při parsování data registrace pro uživatele ${user.email}: ${user.registered_at}`, e);
             return false; // Chyba při parsování = vyloučíme
        }

        // Podmínka: Je datum registrace V RÁMCI posledních 7 dní? (Větší nebo rovno oneWeekAgo)
        const isWithinLastWeek = registrationDate >= oneWeekAgo;

        // Aplikace filtru podle stavu checkboxu:
        // 1. Pokud je checkbox ZAŠKRTNUTÝ (true), chceme jen ty, co jsou V RÁMCI týdne (isWithinLastWeek === true)
        // 2. Pokud je checkbox NEZAŠKRTNUTÝ (false), chceme jen ty, co jsou MIMO rámec týdne (isWithinLastWeek === false)
        // => Výsledek porovnání (isWithinLastWeek) se musí shodovat se stavem checkboxu (registeredLastWeek)
        if (isWithinLastWeek !== registeredLastWeek) {
             // Pokud se neshodují, uživatel nesplňuje podmínku filtru "Do 7 dnů"
             return false;
        }
        // --- Konec nové logiky filtru ---


        // Pokud uživatel prošel všemi filtry, vrátíme true
        return true;
      })
      .sort((a, b) => { // Řazení zůstává stejné
        if (sortOption === "email") {
          return a.email.localeCompare(b.email);
        } else if (sortOption === "registration") {
          // Zajistíme, že porovnáváme platná data, i když by měla být již odfiltrována
          const dateA = new Date(a.registered_at);
          const dateB = new Date(b.registered_at);
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0; // Neřadit, pokud je některé datum neplatné
          return dateA - dateB;
        }
        return 0;
      });
    setFilteredUsers(filtered);
    // Aktualizujeme status text, pokud nejsou nalezeni uživatelé po filtrování
    if (users.length > 0 && filtered.length === 0 && statusText === "Načítám uživatele...") {
        setStatusText("Žádní uživatelé nevyhovují filtrům.");
    } else if (users.length === 0 && statusText === "Načítám uživatele...") {
        // Pokud se ještě nenahráli uživatelé, necháme původní text
    } else if (filtered.length > 0 && statusText !== "Načítám uživatele...") {
        // Pokud uživatelé jsou, ale předtím nebyli, resetujeme status text (volitelné)
        // setStatusText(""); // Nebo ponechat prázdný
    }

  }, [filter, users, selectedStages, sortOption, registeredLastWeek, statusText]); // Přidán statusText do závislostí pro aktualizaci textu;

  // Načtení uživatelů z API
  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("users/");
      const sortedUsers = response.data.sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setUsers(sortedUsers);
    } catch (error) {
      console.log("Error getting users!", error);
      setStatusText("Nic jsem nenašel");
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Načtení detailů uživatele (obchody apod.)
  const getUserDetails = async (userId) => {
    setIsUserDataLoading(true);
    try {
      const response = await axiosInstance.get(`user/detail/`);
      setShops(response.data.shops || []);
    } catch (error) {
      console.log("Error getting user details!", error);
      setShops([]);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Převod user_value na odpovídající textový řetězec
  // Pokud je hodnota 0, vracíme "nenastaven"
  const getUserStage = (value) => {
    if (value === 0) return "nenastaven";
    if (value === 0.1) return "0.1";
    if (value === 10) return "10";
    return "";
  };

  // Převod textové volby z modalu na číselnou hodnotu pro PATCH request
  const stageToValue = (stage) => {
    switch (stage) {
      case "0.1":
        return 0.1;
      case "10":
        return 10;
      default:
        return 0;
    }
  };

  // PATCH request pro aktualizaci hodnoty user_value
  const onUpdateValue = async (id, newStage) => {
    setIsUserDataLoading(true);
    try {
      const numericValue = stageToValue(newStage);
      await axiosInstance.patch(`users/${id}/`, {
        user_value: numericValue,
        send_conversion_action: selectedUser?.gclid ? true : false,
      });
      setIsModalOpen(false);
      getUsers();
    } catch (error) {
      console.log("Error updating user value", error);
    } finally {
      setIsUserDataLoading(false);
      setUsers([]);
    }
  };

  // Otevření modalu pro detail/úpravu uživatele
  const handleUserClick = (user) => {
    setSelectedUser(user);
    getUserDetails(user.id);
    setIsModalOpen(true);
  };

  const formatDate = (dateStr) =>
    new Intl.DateTimeFormat("cs-CZ", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).format(new Date(dateStr));

  // Filtrování obchodů pro vybraného uživatele
  const userShops = selectedUser
    ? shops.filter((shop) => shop.users.some((u) => u.id === selectedUser.id))
    : [];

  const handleStageChange = (e) => {
    const { value, checked } = e.target;
    setSelectedStages((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  return (
    <>
      <section className={`${"need-mt"} ${styles.usersSection}`}>
        <h2 className={styles.title}>Vyberte uživatele</h2>
        <div className={styles.sortAndSearchContainer}>
          <input
            type="text"
            placeholder="Vyhledávání"
            className={styles.searchInput}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <select
            className={styles.sortSelect}
            id="sortSelect"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="email">Řazení podle e-mailu</option>
            <option value="registration">Řazení podle data registrace</option>
          </select>
        </div>
        <div className={styles.userListBox}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <div
                key={user.id}
                className={styles.userRow}
                onClick={() => handleUserClick(user)}
              >
                <div className={styles.nlBox}>
                  <span className={styles.userEmail}>{user.email}</span>
                  {user?.gclid && (
                    <span className={styles.gclidLabel}>Google Ads</span>
                  )}
                </div>
                {(user.user_value || user.user_value === 0) && (
                  <span className={styles.userValue}>
                    {getUserStage(user.user_value)}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p className={styles.noUsers}>{statusText}</p>
          )}
        </div>
        <div className={styles.checkboxContainer}>
          {["nenastaven", "0.1", "10"].map((stageOption) => (
            <label
              key={stageOption}
              className={`checkbox ${styles.checkboxLabel}`}
            >
              <input
                type="checkbox"
                name="stage"
                value={stageOption}
                checked={selectedStages[stageOption]}
                onChange={handleStageChange}
              />
              {stageOption}
              <span className="checkbox__checker"></span>
            </label>
          ))}
          <label className={`checkbox ${styles.checkboxLabel}`}>
            <input
              type="checkbox"
              name="registeredLastWeek"
              checked={registeredLastWeek}
              onChange={(e) => setRegisteredLastWeek(e.target.checked)}
            />
            Do 7 dnů
            <span className="checkbox__checker"></span>
          </label>
        </div>
      </section>

      {isModalOpen && selectedUser && (
        <ModalUsers
          userId={selectedUser.id}
          title={selectedUser.email}
          userValue={selectedUser.user_value}
          userRegistered={selectedUser.registered_at}
          shops={userShops}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onUpdateValue}
          confirmText=""
          cancelText="Zavřít"
          getUserStage={getUserStage}
          formatDate={formatDate}
          gclid={selectedUser.gclid}
        />
      )}
    </>
  );
};

export default Users;
